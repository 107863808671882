import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import { useSelector } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
const PrebookingScreen = () => {
  const [bookings, setBookings] = useState();
  const navigate = useNavigate();

  const adminLogin = useSelector((state) => state.adminLogin);
  const { admin } = adminLogin;
  useEffect(() => {
    const getPrebookingDetails = async () => {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${admin.token}`,
          },
        };

        const { data } = await axios.get(
          "/api/form/preorders",

          config
        );
        if (data) {
          setBookings(data);
        }
      } catch (error) {}
    };

    getPrebookingDetails();
  }, []);

  return (
    <div className="container-fluid  ps-0">
      <div className="row ">
        <div className="col-lg-2 ">
          <Sidebar active={11} />
        </div>
        <div
          className="col-lg-10 p-0"
          style={{
            overflowY: "scroll",
            height: "100vh",
          }}
        >
          {bookings ? (
            <div className="mt-2 ps-0 " style={{ paddingTop: "2.6em" }}>
              <div className="d-flex align-items-center justify-content-between pe-5">
                <h4 className="mb-3 fw-bold">X-Factor Prebookings</h4>

                <h4 className="mb-3 fw-bold">
                  {bookings && bookings.length} |{" "}
                  {bookings &&
                    bookings.filter((item) => item.paymentStatus === true)
                      .length}{" "}
                  |{" "}
                  {bookings &&
                    bookings.filter((item) => item.fullPaymentStatus === true)
                      .length}{" "}
                </h4>
              </div>

              <nav>
                <div class="nav nav-tabs" id="nav-tab" role="tablist">
                  <button
                    class="nav-link active"
                    id="nav-home-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-home"
                    type="button"
                    role="tab"
                    aria-controls="nav-home"
                    aria-selected="true"
                  >
                    ALL
                  </button>
                  <button
                    class="nav-link"
                    id="nav-profile-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-profile"
                    type="button"
                    role="tab"
                    aria-controls="nav-profile"
                    aria-selected="false"
                  >
                    Paid ₹999
                  </button>
                  <button
                    class="nav-link"
                    id="nav-contact-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-contact"
                    type="button"
                    role="tab"
                    aria-controls="nav-contact"
                    aria-selected="false"
                  >
                    Full Payment
                  </button>
                </div>
              </nav>
              <div class="tab-content" id="nav-tabContent">
                <div
                  class="tab-pane  fade show active"
                  id="nav-home"
                  style={{
                    height: "80vh",
                    overflowY: "scroll",
                  }}
                  role="tabpanel"
                  aria-labelledby="nav-home-tab"
                >
                  <div className="table-responsive">
                    <table className="  w-100 border-round ">
                      <thead>
                        <tr className="">
                          <th className="p-4">Date</th>
                          <th className="p-4">Name</th>
                          <th className="p-4">Email</th>
                          <th className="p-4">Mobile Number</th>
                          <th className="p-4">Bike & Color</th>

                          <th className="p-4">Free Gift</th>
                        </tr>
                      </thead>
                      <tbody className="bg-dark-1">
                        {bookings &&
                          bookings.map((item) => (
                            <tr
                              className={`${
                                item.fullPaymentStatus
                                  ? "bg-success text-light"
                                  : item.paymentStatus
                                  ? "bg-dark text-light"
                                  : ""
                              } `}
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                navigate(`/prebookings/${item._id}`)
                              }
                            >
                              <td className="ps-4 pt-3 pb-3 ">
                                {item.createdAt.slice(0, 10)}
                              </td>
                              <td className="ps-4 pt-3 pb-3 ">{item.name}</td>
                              <td className="ps-4 pt-3 pb-3 ">{item.email}</td>
                              <td className="ps-4 pt-3 pb-3 ">
                                {item.phoneNumber}
                              </td>

                              <td className="ps-4 pt-3 pb-3 ">
                                {item.bikeInfo.bikeName} | {item.bikeInfo.color}
                              </td>
                              <td className="ps-4 pt-3 pb-3 ">
                                {item.freeGift ? "YES" : "NO"}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div
                  class="tab-pane  fade show "
                  id="nav-profile"
                  style={{
                    height: "80vh",
                    overflowY: "scroll",
                  }}
                  role="tabpanel"
                  aria-labelledby="nav-home-tab"
                >
                  <div className="table-responsive">
                    <table className="  w-100 border-round ">
                      <thead>
                        <tr className="">
                          <th className="p-4">Date</th>
                          <th className="p-4">Name</th>
                          <th className="p-4">Email</th>
                          <th className="p-4">Mobile Number</th>
                          <th className="p-4">Bike & Color</th>

                          <th className="p-4">Free Gift</th>
                        </tr>
                      </thead>
                      <tbody className="bg-dark-1">
                        {bookings &&
                          bookings
                            .filter((item) => item.paymentStatus === true)
                            .map((item) => (
                              <tr
                                className={`${
                                  item.fullPaymentStatus
                                    ? "bg-success text-light"
                                    : item.paymentStatus
                                    ? "bg-dark text-light"
                                    : ""
                                }`}
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  navigate(`/prebookings/${item._id}`)
                                }
                              >
                                <td className="ps-4 pt-3 pb-3 ">
                                  {item.createdAt.slice(0, 10)}
                                </td>
                                <td className="ps-4 pt-3 pb-3 ">{item.name}</td>
                                <td className="ps-4 pt-3 pb-3 ">
                                  {item.email}
                                </td>
                                <td className="ps-4 pt-3 pb-3 ">
                                  {item.phoneNumber}
                                </td>

                                <td className="ps-4 pt-3 pb-3 ">
                                  {item.bikeInfo.bikeName} |{" "}
                                  {item.bikeInfo.color}
                                </td>
                                <td className="ps-4 pt-3 pb-3 ">
                                  {item.freeGift ? "YES" : "NO"}
                                </td>
                              </tr>
                            ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div
                  class="tab-pane  fade show "
                  id="nav-contact"
                  style={{
                    height: "80vh",
                    overflowY: "scroll",
                  }}
                  role="tabpanel"
                  aria-labelledby="nav-home-tab"
                >
                  <div className="table-responsive">
                    <table className="  w-100 border-round ">
                      <thead>
                        <tr className="">
                          <th className="p-4">Date</th>
                          <th className="p-4">Name</th>
                          <th className="p-4">Email</th>
                          <th className="p-4">Mobile Number</th>
                          <th className="p-4">Bike & Color</th>

                          <th className="p-4">Free Gift</th>
                        </tr>
                      </thead>
                      <tbody className="bg-dark-1">
                        {bookings &&
                          bookings
                            .filter((item) => item.fullPaymentStatus === true)
                            .map((item) => (
                              <tr
                                className={`${
                                  item.paymentStatus && "bg-success text-light"
                                }`}
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  navigate(`/prebookings/${item._id}`)
                                }
                              >
                                <td className="ps-4 pt-3 pb-3 ">
                                  {item.updatedAt.slice(0, 10)}
                                </td>
                                <td className="ps-4 pt-3 pb-3 ">{item.name}</td>
                                <td className="ps-4 pt-3 pb-3 ">
                                  {item.email}
                                </td>
                                <td className="ps-4 pt-3 pb-3 ">
                                  {item.phoneNumber}
                                </td>

                                <td className="ps-4 pt-3 pb-3 ">
                                  {item.bikeInfo.bikeName} |{" "}
                                  {item.bikeInfo.color}
                                </td>
                                <td className="ps-4 pt-3 pb-3 ">
                                  {item.freeGift ? "YES" : "NO"}
                                </td>
                              </tr>
                            ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </div>
  );
};

export default PrebookingScreen;
