import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useParams } from "react-router-dom";

import { getOrderByID } from "../actions/orderActions";

const OrderScreen = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getOrderByID(id));
  }, []);

  const [loading, setLoading] = useState(false);

  const orderById = useSelector((state) => state.orderById);
  const { order } = orderById;
  console.log(order);

  const adminLogin = useSelector((state) => state.adminLogin);
  const { admin } = adminLogin;

  const [carrier, setCarrier] = useState("");
  const [docketId, setDocketId] = useState("");

  useEffect(() => {
    if (order) {
      setCarrier(order?.orderInfo?.logistics?.carrier);
      setDocketId(order?.orderInfo?.logistics?.docketId);
    }
  }, [order, dispatch]);

  const currencyFormat = (num) => {
    return "₹" + " " + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  const getISTTime = (time) => {
    var localTime = new Date(time).toLocaleString(undefined, {
      timeZone: "Asia/Kolkata",
    });
    return localTime;
  };

  const resentEmail = async () => {
    if (window.confirm("Resend Confirmation Email ?")) {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${admin.token}`,
          },
        };

        const { data } = await axios.post(
          "/api/order/send-email",
          { order: order.orderInfo },
          config
        );

        if (data) {
          alert("MAIL SENT SUCCESSFULLY");
        }
      } catch (error) {
        alert(error);
      }
    }
  };

  const setTrackingLink = (link) => {
    switch (link) {
      case "Safexpress":
        return "http://www.safexpress.com/Portal/faces/TrackShipment.jspx?_afrLoop=23129212080825529&_afrWindowMode=0&_afrWindowId=1ainbz1l4w&_adf.ctrl-state=1grtuyrup_29#!%40%40%3F_afrWindowMode%3D0%26_afrLoop%3D23129212080825529%26_afrWindowId%3D1ainbz1l4w%26_adf.ctrl-state%3D1grtuyrup_33";

      case "Xpressbees":
        return "https://www.xpressbees.com/track/";

      case "BOX N FREIGHT":
        return "https://boxnfreight.in/";

      case "Delhivery":
        return "https://www.delhivery.com/";

      case "Trackon":
        return "https://trackon.in/Tracking/t2/MultipleTracking";

      default:
        break;
    }
  };

  const AddTrackingDetails = async (e) => {
    e.preventDefault();

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${admin.token}`,
        },
      };

      const { data } = await axios.post(
        "/api/order/add-tracking",
        {
          id: order.orderInfo._id,
          carrier,
          link: setTrackingLink(carrier),
          docketId,
        },
        config
      );

      if (data) {
        alert("Tracking Details Added Successfully");
        dispatch(getOrderByID(id));
      }
    } catch (error) {
      alert(error);
    }
  };

  const markAsDelivered = async () => {
    if (window.confirm("Do You Want to Mark this Order as Delivered ?")) {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${admin.token}`,
          },
        };

        const { data } = await axios.post(
          "/api/order/delivered",
          {
            id: order.orderInfo._id,
          },
          config
        );

        if (data) {
          alert("Order is Marked As Delivered");
          dispatch(getOrderByID(id));
        }
      } catch (error) {
        alert(error);
      }
    }
  };

  return (
    <>
      {/* // TRACKING DETAILS MODAL */}
      <div
        class="modal fade"
        id="trackingModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog  modal-dialog-centered">
          <form className="modal-content" onSubmit={AddTrackingDetails}>
            <div class="">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Tracking Details
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <div class="form-floating">
                  <select
                    class="form-select mb-3"
                    value={carrier}
                    required
                    onChange={(e) => setCarrier(e.target.value)}
                    id="floatingSelect"
                    aria-label="Floating label select example"
                  >
                    <option selected disabled></option>
                    <option value="Safexpress">Safexpress</option>
                    <option value="Xpressbees">Xpressbees</option>
                    <option value="BOX N FREIGHT">BOX N FREIGHT</option>
                    <option value="Delhivery">Delhivery</option>
                    <option value="	Trackon">Trackon</option>
                  </select>
                  <label for="">Logistics Partner</label>
                </div>
                <div class="form-floating ">
                  <input
                    type="text"
                    required
                    value={docketId}
                    onChange={(e) => setDocketId(e.target.value)}
                    class="form-control"
                    id="floatingInput"
                    placeholder="name@example.com"
                  />
                  <label for="floatingInput">Docket Number</label>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="submit"
                  class="btn btn-primary"
                  data-bs-dismiss="modal"
                >
                  Save changes
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="container-fluid ps-0 ">
        <div className="row ">
          <div className="col-lg-2">
            <Sidebar active={13} />
          </div>
          <div
            className="col-lg-10 position-relative p-0"
            style={{
              height: "100vh",
              overflowY: "scroll",
            }}
          >
            {order && (
              <>
                {order.orderInfo.test === true && (
                  <h1
                    className="text-danger fw-bold position-absolute"
                    style={{
                      fontSize: "19em",
                      top: "50%",
                      left: "50%",
                      translate: "-50% -50%",
                    }}
                  >
                    TEST
                  </h1>
                )}
                <div className="d-flex justify-content-between">
                  <div className="mt-3  p-3">
                    <span className="  d-block">Ordered on</span>
                    <h3 className={`fw-bold `}>
                      {getISTTime(order.orderInfo.createdAt)}
                    </h3>
                    <h6>Order Status: {order.orderInfo.orderStatus}</h6>
                  </div>
                  <div
                    className="mt-3 d-flex align-items-center  p-3 pe-5"
                    style={{ gap: "2rem" }}
                  >
                    {order.orderInfo.paymentStatus &&
                      order.orderInfo.orderStatus == "Dispatched" && (
                        <div>
                          <button
                            onClick={markAsDelivered}
                            style={{
                              borderRadius: "0px",
                              boxShadow: "-4px 4px #252730",
                            }}
                            className="btn btn-danger "
                          >
                            Mark As Delivered
                          </button>
                        </div>
                      )}
                    {order.orderInfo.paymentStatus && (
                      <div>
                        <button
                          data-bs-toggle="modal"
                          data-bs-target="#trackingModal"
                          style={{
                            borderRadius: "0px",
                            boxShadow: "-4px 4px #252730",
                          }}
                          className="btn btn-warning fw-bold"
                        >
                          {order.orderInfo.orderStatus == "Delivered"
                            ? "View Tracking Details"
                            : "Add Tracking Details"}
                        </button>
                      </div>
                    )}
                    {order.orderInfo.paymentStatus && (
                      <div>
                        <button
                          style={{
                            borderRadius: "0px",
                            boxShadow: "-4px 4px #252730",
                          }}
                          onClick={resentEmail}
                          className="btn btn-primary fw-bold"
                        >
                          Resend Confirmation Email
                        </button>
                      </div>
                    )}

                    <div>
                      <span className="">Payment Status</span>
                      {order.orderInfo.paymentStatus ? (
                        <h3
                          className=""
                          style={{
                            color: "#10b068",
                            fontWeight: "900",
                          }}
                        >
                          Paid
                        </h3>
                      ) : (
                        <h3
                          style={{
                            color: "red",
                            fontWeight: "900",
                          }}
                        >
                          Unpaid
                        </h3>
                      )}
                    </div>
                  </div>
                </div>

                <div className="container-fluid">
                  <div className="row mt-3">
                    <div className="col-12 col-md-7  ">
                      <div
                        className="bg-light  p-4"
                        style={{
                          borderRadius: "10px",
                        }}
                      >
                        <h6 className="mb-2">
                          Order Id:{" "}
                          <span className="ms-3">
                            {" "}
                            EM
                            {String(order.orderInfo._id)
                              .slice(-6)

                              .toUpperCase()}
                          </span>
                        </h6>
                        <hr />
                        <p>
                          name :{" "}
                          <span className="ms-3"> {order.orderInfo.name}</span>
                        </p>
                        <p>
                          email :{" "}
                          <span className="ms-3">{order.orderInfo.email}</span>
                        </p>
                        <p>
                          Phone Number :{" "}
                          <span className="ms-3">
                            {order.orderInfo.phoneNumber}
                          </span>
                        </p>
                        {order.orderInfo.gstin && (
                          <p>
                            GSTIN :{" "}
                            <span className="ms-3">
                              {order.orderInfo.gstin}
                            </span>
                          </p>
                        )}
                      </div>
                      <div className=" mt-4">
                        <div
                          className="bg-light p-4"
                          style={{
                            borderRadius: "10px",
                          }}
                        >
                          <h6 className="mb-2">Shipping Details:</h6>
                          <hr />
                          <div className="d-flex">
                            <p className="col-2">Address : </p>{" "}
                            <p>
                              {order.orderInfo.address.addressLine1},{" "}
                              {order.orderInfo.address.addressLine2},{" "}
                              {order.orderInfo.address.city},{" "}
                              {order.orderInfo.address.state}.{" "}
                              {order.orderInfo.address.pincode}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex pe-3" style={{ gap: "1rem" }}>
                        <div
                          className="mt-4  col-5 bg-light p-4"
                          style={{
                            borderRadius: "10px",
                          }}
                        >
                          <h6 className="mb-2">Order Invoice Details:</h6>
                          <hr />
                          <p className="d-flex justify-content-between pe-3">
                            Total Items :{" "}
                            <span className="">
                              {" "}
                              {order.orderInfo.items.reduce(
                                (acc, item) => acc + item.qty,
                                0
                              )}
                            </span>
                          </p>
                          <p className="d-flex justify-content-between pe-3">
                            Sub Total :{" "}
                            <span className="">
                              {" "}
                              {currencyFormat(
                                order.orderInfo.items.reduce(
                                  (acc, item) => acc + item.price,
                                  0
                                )
                              )}
                            </span>
                          </p>
                          {order.orderInfo.amount < 1450 && (
                            <p className="d-flex justify-content-between pe-3">
                              Delivery :{" "}
                              <span className="">
                                {currencyFormat(
                                  order.orderInfo.amount -
                                    order.orderInfo.items.reduce(
                                      (acc, item) => acc + item.price,
                                      0
                                    )
                                )}
                              </span>
                            </p>
                          )}

                          {order.orderInfo.promoCode && (
                            <p className="d-flex justify-content-between pe-3">
                              Promocode :{" "}
                              <span className="">
                                {" "}
                                {order.orderInfo.promoCode}
                              </span>
                            </p>
                          )}

                          <p className="d-flex justify-content-between pe-3">
                            Total Amount
                            <span className="fw-normal">
                              {" "}
                              {currencyFormat(order.orderInfo.amount)}
                            </span>
                          </p>
                        </div>
                        <div
                          className=" col-7 p-4 mt-4 bg-light"
                          style={{
                            borderRadius: "10px",
                          }}
                        >
                          <h6 className="mb-2">Payment Details:</h6>
                          <hr />
                          {order.paymentInfo ? (
                            <>
                              {order.orderInfo.paymentMethod === "PayU" ? (
                                <>
                                  <p className="d-flex justify-content-between pe-3">
                                    Payment Method:
                                    <span className="fw-normal">PayU</span>
                                  </p>
                                  <p className="d-flex justify-content-between pe-3">
                                    PayU ID:
                                    <span className="fw-normal">
                                      {order.paymentInfo.payuId}
                                    </span>
                                  </p>
                                  <p className="d-flex justify-content-between pe-3">
                                    Bank Ref No:
                                    <span className="fw-normal">
                                      {order.paymentInfo.bankRefNum}
                                    </span>
                                  </p>
                                  <p className="d-flex justify-content-between pe-3">
                                    Amount Paid:
                                    <span className="fw-normal">
                                      ₹ {order.paymentInfo.amount}
                                    </span>
                                  </p>
                                  <p className="d-flex justify-content-between pe-3">
                                    Status:
                                    <span className="fw-normal">
                                      {order.paymentInfo.status}
                                    </span>
                                  </p>
                                  {/* <p className="d-flex justify-content-between pe-3">
                                    Method:
                                    <span className="fw-normal text-uppercase">
                                      {order.paymentInfo.method}
                                    </span>
                                  </p> */}
                                </>
                              ) : (
                                <>
                                  <p className="d-flex justify-content-between pe-3">
                                    Payment Method:
                                    <span className="fw-normal">Razorpay</span>
                                  </p>
                                  <p className="d-flex justify-content-between pe-3">
                                    RazorPay Order ID:
                                    <span className="fw-normal">
                                      {order.paymentInfo.order_id}
                                    </span>
                                  </p>
                                  <p className="d-flex justify-content-between pe-3">
                                    RazorPay Payment ID:
                                    <span className="fw-normal">
                                      {order.paymentInfo.id}
                                    </span>
                                  </p>
                                  <p className="d-flex justify-content-between pe-3">
                                    Amount Paid:
                                    <span className="fw-normal">
                                      ₹{" "}
                                      {String(
                                        order.paymentInfo.amount
                                      ).substring(
                                        0,
                                        String(order.paymentInfo.amount)
                                          .length - 2
                                      )}
                                    </span>
                                  </p>
                                  <p className="d-flex justify-content-between pe-3">
                                    Status:
                                    <span className="fw-normal">
                                      {order.paymentInfo.status}
                                    </span>
                                  </p>
                                  <p className="d-flex justify-content-between pe-3">
                                    Method:
                                    <span className="fw-normal text-uppercase">
                                      {order.paymentInfo.method}
                                    </span>
                                  </p>
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              <p className="d-flex justify-content-between pe-3">
                                Payment Method:
                                <span className="fw-normal">Razorpay</span>
                              </p>
                              {order.orderInfo.paymentDetails
                                .razorpayOrderId && (
                                <>
                                  <p className="d-flex justify-content-between pe-3">
                                    RazorPay Order ID:
                                    <span className="fw-normal">
                                      {order.paymentOrderInfo.id}
                                    </span>
                                  </p>
                                  <p className="d-flex justify-content-between pe-3">
                                    Amount:
                                    <span className="fw-normal">
                                      ₹{" "}
                                      {String(
                                        order.paymentOrderInfo.amount
                                      ).substring(
                                        0,
                                        String(order.paymentOrderInfo.amount)
                                          .length - 2
                                      )}
                                    </span>
                                  </p>
                                  <p className="d-flex justify-content-between pe-3">
                                    Amount Due:
                                    <span className="fw-normal">
                                      ₹{" "}
                                      {String(
                                        order.paymentOrderInfo.amount_due
                                      ).substring(
                                        0,
                                        String(
                                          order.paymentOrderInfo.amount_due
                                        ).length - 2
                                      )}
                                    </span>
                                  </p>
                                  <p className="d-flex justify-content-between pe-3">
                                    Amount Paid:
                                    <span className="fw-normal">
                                      ₹{" "}
                                      {String(
                                        order.paymentOrderInfo.amount_paid
                                      ).substring(
                                        0,
                                        String(
                                          order.paymentOrderInfo.amount_paid
                                        ).length - 2
                                      )}
                                    </span>
                                  </p>
                                  <p className="d-flex justify-content-between pe-3">
                                    Status:
                                    <span className="fw-normal">
                                      {order.paymentOrderInfo.status}
                                    </span>
                                  </p>
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-12 col-md-5  ">
                      <div
                        className="bg-light p-4"
                        style={{
                          borderRadius: "10px",
                        }}
                      >
                        <h6 className="mb-2">Items:</h6>
                        <hr />
                        {order.orderInfo.items.map((item) => (
                          <>
                            <div className="row  shipping-item-info mt-4 align-items-center">
                              <div
                                className="col-4 p-2 "
                                style={{
                                  background: "#F4F5F7",
                                }}
                              >
                                {item.cartImages &&
                                item.cartImages[item.model] ? (
                                  <img
                                    src={item.cartImages[item.model]}
                                    className="img-fluid"
                                    alt=""
                                  />
                                ) : (
                                  <div>
                                    {" "}
                                    <img
                                      src={item.cartImages[item.color]}
                                      className="img-fluid"
                                      alt=""
                                    />
                                  </div>
                                )}
                              </div>
                              <div className="col-8 p-3 pt-0">
                                <div className="mb-3 item-name pb-2">
                                  <h5 className="fw-bold mb-0 color-dark">
                                    {item.name}{" "}
                                    {item.variant !== "" ? (
                                      <>
                                        <span>|</span>{" "}
                                        <span>
                                          {
                                            item.variants[item.variant]
                                              .variantName
                                          }
                                        </span>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </h5>
                                </div>
                                <p className="fw-light mb-2 w-100 d-flex justify-content-between ">
                                  {" "}
                                  <span className="col-7">Color :</span>{" "}
                                  {item.models[item.model] &&
                                    item.models[item.model].colorName && (
                                      <span className="fw-bold col-5">
                                        {item.models[item.model].colorName}
                                      </span>
                                    )}
                                </p>
                                <p className="fw-light mb-2 w-100 d-flex justify-content-between ">
                                  {" "}
                                  <span className="col-7">Quantity :</span>{" "}
                                  <span className="fw-bold d-flex align-items-center justify-content-between  col-5">
                                    {" "}
                                    <span>{item.qty}</span>{" "}
                                  </span>
                                </p>
                                <p className="fw-light mb-0 w-100 d-flex justify-content-between ">
                                  {" "}
                                  <span className="col-7">Price :</span>{" "}
                                  <span className="fw-bold d-flex align-items-center justify-content-between  col-5">
                                    {" "}
                                    <span>
                                      {currencyFormat(item.qty * item.price)}
                                    </span>{" "}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderScreen;
