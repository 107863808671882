import React, { useEffect } from "react";
import Sidebar from "../components/Sidebar";
import "../css/dealer.css";
import { getAllContact } from "../actions/formActions";
import { useDispatch, useSelector } from "react-redux";
const ContactUsScreen = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllContact());
  }, [dispatch]);

  const getContact = useSelector((state) => state.getContact);
  const { contacts } = getContact;
  return (
    <div className="container-fluid ps-0 ">
      <div className="row ">
        <div className="col-lg-2">
          <Sidebar active={11} />
        </div>
        <div
          className="col-lg-10 p-0"
          style={{
            overflowY: "scroll",
            height: "100vh",
          }}
        >
          <div className="mt-2 ps-3 pe-3" style={{ paddingTop: "2.6em" }}>
            <div className="d-flex align-items-center justify-content-between pe-3">
              <h4 className="mb-3 fw-bold">Contact Us</h4>
              <div className="d-flex mb-3  align-items-center"></div>
            </div>

            <table className="w-100">
              <thead>
                <tr className="">
                  <th className="p-4">Date</th>

                  <th className="p-4">Name</th>
                  <th className="p-4">Email</th>

                  <th className="p-4">Mobile Number</th>
                  <th className="p-4">Query Type</th>
                  <th className="p-4">Message</th>
                </tr>
              </thead>
              <tbody className="bg-dark-1">
                {contacts &&
                  contacts.map((item, i) => (
                    <tr>
                      <td className="ps-4 pt-3 pb-3 ">
                        {String(item.createdAt).slice(0, 10)}
                      </td>

                      <td className="ps-4 pt-3 pb-3 ">{item.name}</td>
                      <td className="ps-4 pt-3 pb-3 ">{item.email}</td>
                      <td className="ps-4 pt-3 pb-3 ">{item.phoneNumber}</td>
                      <td className="ps-4 pt-3 pb-3 ">{item.queryType}</td>
                      <td className="ps-4 pt-3 pb-3 ">{item.message}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUsScreen;
