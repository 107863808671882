export const GET_ALL_ORDERS_REQUEST = 'GET_ALL_ORDERS_REQUEST'
export const GET_ALL_ORDERS_SUCCESS = 'GET_ALL_ORDERS_SUCCESS'
export const GET_ALL_ORDERS_FAIL = 'GET_ALL_ORDERS_FAIL'



export const GET_ORDER_BY_ID_REQUEST = 'GET_ORDER_BY_ID_REQUEST'
export const GET_ORDER_BY_ID_SUCCESS = 'GET_ORDER_BY_ID_SUCCESS'
export const GET_ORDER_BY_ID_FAIL = 'GET_ORDER_BY_ID_FAIL'




export const GET_ALL_PAID_ORDERS_REQUEST =   "GET_ALL_PAID_ORDERS_REQUEST"
export const GET_ALL_PAID_ORDERS_SUCCESS =   "GET_ALL_PAID_ORDERS_SUCCESS"
export const GET_ALL_PAID_ORDERS_FAIL =   "GET_ALL_PAID_ORDERS_FAIL"
export const GET_ALL_UNPAID_ORDERS_REQUEST =   "GET_ALL_UNPAID_ORDERS_REQUEST"
export const GET_ALL_UNPAID_ORDERS_SUCCESS =   "GET_ALL_UNPAID_ORDERS_SUCCESS"
export const GET_ALL_UNPAID_ORDERS_FAIL =   "GET_ALL_UNPAID_ORDERS_FAIL"

export const GET_ALL_ORDERS_DASHBOARD_SUCCESS = "GET_ALL_ORDERS_DASHBOARD_SUCCESS"