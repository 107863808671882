import React, { useState, useEffect } from "react";
import Sidebar from "../components/Sidebar";
import "../css/testride.css";
import { getAllTestrides } from "../actions/formActions";
import { useDispatch, useSelector } from "react-redux";
import exportFromJSON from "export-from-json";

const TestRideScreen = () => {
  const dispatch = useDispatch();

  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  useEffect(() => {
    dispatch(getAllTestrides(from, to));
  }, [dispatch, from, to]);

  const getTestrides = useSelector((state) => state.getTestrides);
  const { testRides } = getTestrides;
  const searchQuery = () => {
    dispatch(getAllTestrides(from, to));
  };
  const exportCSV = () => {
    const data = testRides;
    for (let i = 0; i < data.length; i++) {
      delete data[i]._id;
      delete data[i].updatedAt;
      delete data[i].__v;
      data[i].createdAt = data[i].createdAt.slice(0, 10);
    }
    const fileName = "testRides";
    const exportType = exportFromJSON.types.csv;
    exportFromJSON({ data, fileName, exportType });
  };
  return (
    <div className="container-fluid ps-0 ">
      <div className="row ">
        <div className="col-lg-2">
          <Sidebar active={7} />
        </div>
        <div
          className="col-lg-10 p-0"
          style={{
            overflowY: "scroll",
            height: "100vh",
          }}
        >
          <div className="mt-3 p-3">
            <div className="d-flex align-items-center justify-content-between pe-3">
              <h4 className="mb-3 fw-bold">TestRide</h4>
              <div className="d-flex  align-items-center">
                <div class="form-floating mb-3 me-3">
                  <input
                    type="date"
                    class="form-control"
                    id="floatingInput"
                    value={from}
                    onChange={(e) => setFrom(e.target.value)}
                    placeholder="name@example.com"
                  />
                  <label for="floatingInput text-grey ">From</label>
                </div>
                <div class="form-floating mb-3 me-3">
                  <input
                    type="date"
                    class="form-control"
                    id="floatingInput"
                    value={to}
                    onChange={(e) => setTo(e.target.value)}
                    placeholder="name@example.com"
                  />
                  <label for="floatingInput text-grey">To</label>
                </div>
                <div class="form-floating mb-3 me-3">
                  <button
                    onClick={searchQuery}
                    className="btn  btn-primary text-light"
                  >
                    <i class="fa-solid fa-magnifying-glass"></i>
                  </button>
                </div>
                <div class="form-floating mb-3 me-3">
                  <button
                    onClick={exportCSV}
                    className="btn btn-success text-light"
                  >
                    <i class="fa-solid fa-download"></i>
                  </button>
                </div>
              </div>
            </div>
            <table className=" w-100">
              <thead>
                <tr className="">
                  <th className="p-4">Sr. No.</th>
                  <th className="p-4">Date</th>
                  <th className="p-4">Name</th>
                  <th className="p-4">Mobile Number</th>
                  <th className="p-4">Email</th>
                  <th className="p-4">Address</th>
                </tr>
              </thead>

              <tbody className="bg-dark-1">
                {testRides &&
                  testRides.map((ride, i) => (
                    <tr>
                      <td className="ps-4 pt-3 pb-3 ">{i + 1}</td>
                      <td className="ps-4 pt-3 pb-3 ">
                        {ride.createdAt.slice(0, 10)}
                      </td>
                      <td className="ps-4 pt-3 pb-3 ">{ride.userName}</td>
                      <td className="ps-4 pt-3 pb-3 ">
                        {ride.userPhoneNumber}
                      </td>
                      <td className="ps-4 pt-3 pb-3 ">{ride.userEmail}</td>
                      <td className="ps-4 pt-3 pb-3 ">{ride.address}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestRideScreen;
