import React, { useEffect, useState } from "react"
import Sidebar from "../components/Sidebar"
import "../css/dealer.css"
import axios from "axios"
import { useSelector } from "react-redux"
const NotifyMeScreen = () => {
	const adminLogin = useSelector(
		(state) => state.adminLogin,
	)
	const { admin } = adminLogin

	const [notify, setNotify] = useState()

	useEffect(() => {
		const getNotifyMe = async () => {
			try {
				const config = {
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${admin.token}`,
					},
				}
				const { data } = await axios.get(
					"/api/form/notify",
					config,
				)
				if (data) {
					setNotify(data)
				}
			} catch (error) {
				console.log(error)
			}
		}
		getNotifyMe()
	}, [])

	return (
		<div className='container-fluid ps-0 '>
			<div className='row '>
				<div className='col-lg-2'>
					<Sidebar active={9} />
				</div>
				<div
					className='col-lg-10 p-0'
					style={{
						overflowY: "scroll",
						height: "100vh",
					}}>
					<div
						className='mt-2 ps-3 pe-3'
						style={{ paddingTop: "2.6em" }}>
						<div className='d-flex align-items-center justify-content-between pe-3'>
							<h4 className='mb-3 fw-bold'>
								Notify me -{" "}
								{notify && notify.length}
							</h4>
							<div className='d-flex mb-3  align-items-center'></div>
						</div>

						<div className='row'>
							<div className='col-8'>
								<table className='w-100'>
									<thead>
										<tr className=''>
											<th className='p-4'>
												Date
											</th>

											<th className='p-4'>
												email
											</th>

											<th className='p-4'>
												Mobile Number
											</th>
											<th className='p-4'>
												bike
											</th>
										</tr>
									</thead>
									<tbody className='bg-dark-1'>
										{notify &&
											notify.map((item, i) => (
												<tr>
													<td className='ps-4 pt-3 pb-3 '>
														{String(
															item.createdAt,
														).slice(0, 10)}
													</td>

													<td className='ps-4 pt-3 pb-3 '>
														{item.email}
													</td>
													<td className='ps-4 pt-3 pb-3 '>
														{item.phoneNumber}
													</td>
													<td className='ps-4 pt-3 pb-3 '>
														{item.bike}
													</td>
												</tr>
											))}
									</tbody>
								</table>
							</div>
							<div className='col-4  border-round  '>
								<iframe
									title='sales graph'
									style={{
										height: "700px",

										borderRadius: "15px",
										background: "#fff",
										boxShadow:
											"0px 2px 10px 0 rgba(70, 76, 79, .2)",
									}}
									className='w-100 pt-4'
									src='https://charts.mongodb.com/charts-project-0-dblfi/embed/charts?id=64749b15-2bd3-412f-89c4-068df9b8abb0&maxDataAge=3600&theme=light&autoRefresh=true'></iframe>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default NotifyMeScreen
