import React, { useEffect } from "react";
import Sidebar from "../components/Sidebar";
import "../css/dealer.css";
import { useDispatch, useSelector } from "react-redux";
import { getAllInsurance } from "../actions/formActions";
import exportFromJSON from "export-from-json";

const InsuranceScreen = () => {
  const dispatch = useDispatch();

  const getInsurance = useSelector((state) => state.getInsurance);
  const { insurance } = getInsurance;
  const exportCSV = () => {
    const data = insurance;
    for (let i = 0; i < data.length; i++) {
      delete data[i]._id;
      delete data[i].updatedAt;
      delete data[i].__v;
      data[i].createdAt = data[i].createdAt.slice(0, 10);
    }
    const fileName = "insurance";
    const exportType = exportFromJSON.types.csv;
    exportFromJSON({ data, fileName, exportType });
  };

  useEffect(() => {
    dispatch(getAllInsurance());
  }, []);
  return (
    <div className="container-fluid ps-0 ">
      <div className="row ">
        <div className="col-lg-2">
          <Sidebar active={9} />
        </div>
        <div className="col-lg-10 p-0">
          <div
            className="mt-2 ps-3 pe-3"
            style={{
              overflowY: "scroll",
              height: "100vh",
              paddingTop: "2.6em",
            }}
          >
            <div className="d-flex align-items-center justify-content-between pe-3">
              <h4 className="mb-3 fw-bold ">Insurance</h4>
              <div className="d-flex mb-3  align-items-center">
                <button
                  onClick={exportCSV}
                  className="btn btn-success text-light"
                >
                  <i class="fa-solid fa-download"></i>
                </button>
              </div>
            </div>

            <div className="row">
              {insurance &&
                insurance.map((item) => (
                  <div className="col-6 mt-3">
                    <div className="dealer-card insurance-card  text-light p-3">
                      <div className="d-flex mt-3 align-items-center justify-content-between">
                        <p className="m-0 col-6">Name :</p>
                        <span className="col-6">{item.name}</span>
                      </div>
                      <div className="d-flex mt-3 align-items-center justify-content-between">
                        <p className="m-0 col-6">Mobile Number :</p>
                        <span className="col-6">{item.phoneNumber}</span>
                      </div>{" "}
                      <div className="d-flex mt-3 align-items-center justify-content-between">
                        <p className="m-0 col-6">Email:</p>
                        <span className="col-6">{item.email}</span>
                      </div>{" "}
                      <div className="d-flex mt-3 align-items-center justify-content-between">
                        <p className="m-0 col-6">DOB :</p>
                        <span className="col-6">{item.DOB}</span>
                      </div>{" "}
                      <div className="d-flex mt-3 align-items-center justify-content-between">
                        <p className="m-0 col-6">Address :</p>
                        <span className="col-6">
                          {item.address},{item.city},{item.state},
                          {item.postalCode}
                        </span>
                      </div>{" "}
                      <div className="d-flex mt-3 align-items-center justify-content-between">
                        <p className="m-0 col-6">Bike :</p>
                        <span className="col-6">{item.bike}</span>
                      </div>{" "}
                      <div className="d-flex mt-3 align-items-center justify-content-between">
                        <p className="m-0 col-6">Frame Number :</p>
                        <span className="col-6">{item.frameNumber}</span>
                      </div>{" "}
                      <div className="d-flex mt-3 align-items-center justify-content-between">
                        <p className="m-0 col-6">Invoice Number :</p>
                        <span className="col-6">{item.invoiceNumber}</span>
                      </div>{" "}
                      <div className="d-flex mt-3 align-items-center justify-content-between">
                        <p className="m-0 col-6">Nominee Name :</p>
                        <span className="col-6">{item.nomineeName}</span>
                      </div>{" "}
                      <div className="d-flex mt-3 align-items-center justify-content-between">
                        <p className="m-0 col-6">Nominee DOB :</p>
                        <span className="col-6">{item.nomineeDOB}</span>
                      </div>{" "}
                      <div className="d-flex mt-3 align-items-center justify-content-between">
                        <p className="m-0 col-6">Relation with Nominee :</p>
                        <span className="col-6">{item.nomineeRelation}</span>
                      </div>
                      <div className="d-flex mt-3 align-items-center justify-content-between">
                        <p className="m-0 col-6">Time :</p>
                        <span className="col-6">
                          {new Date(item.createdAt).toLocaleString(undefined, {
                            timeZone: "Asia/Kolkata",
                          })}
                        </span>
                      </div>
                      <div className="d-flex mt-3 align-items-center justify-content-between">
                        <p className="m-0 col-6">Invoice :</p>
                        <a
                          href={item.invoice}
                          target="_blank"
                          className="col-6 btn btn-primary"
                        >
                          Invoice
                        </a>
                      </div>{" "}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InsuranceScreen;
