import axios from "axios"
import {
	GET_PROMOCODE_FAIL,
	GET_PROMOCODE_REQUEST,
	GET_PROMOCODE_SUCCESS,
} from "../constants/promocodeConstants"

export const getAllPromocodes =
	() => async (dispatch, getState) => {
		try {
			const {
				adminLogin: { admin },
			} = getState()

			const config = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${admin.token}`,
				},
			}
			dispatch({ type: GET_PROMOCODE_REQUEST })
			const { data } = await axios.get(
				"/api/promocode/all",

				config,
			)

			dispatch({
				type: GET_PROMOCODE_SUCCESS,
				payload: data,
			})
		} catch (error) {
			dispatch({
				type: GET_PROMOCODE_FAIL,
				payload:
					error.response &&
					error.response.data.message
						? error.response.data.message
						: error.message,
			})
		}
	}
