import {
  GET_ALL_ORDERS_FAIL,
  GET_ALL_ORDERS_REQUEST,
  GET_ALL_ORDERS_SUCCESS,
  GET_ORDER_BY_ID_FAIL,
  GET_ORDER_BY_ID_REQUEST,
  GET_ORDER_BY_ID_SUCCESS,
  GET_ALL_PAID_ORDERS_REQUEST,
  GET_ALL_PAID_ORDERS_SUCCESS,
  GET_ALL_PAID_ORDERS_FAIL,
  GET_ALL_UNPAID_ORDERS_REQUEST,
  GET_ALL_UNPAID_ORDERS_SUCCESS,
  GET_ALL_UNPAID_ORDERS_FAIL,
  GET_ALL_ORDERS_DASHBOARD_SUCCESS,
} from "../constants/orderConstants";
import axios from "axios";

export const getAllOrders =
  (page, name, from, to) => async (dispatch, getState) => {
    try {
      const {
        adminLogin: { admin },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${admin.token}`,
        },
      };

      dispatch({ type: GET_ALL_ORDERS_REQUEST });
      const response = await axios.get("/api/order/all", {
        params: { page, name, from, to }, // Send parameters as query parameters
        headers: config.headers,
      });
      const { pagination, orders } = response.data;
      dispatch({
        type: GET_ALL_ORDERS_SUCCESS,
        payload: { pagination, orders },
      });
    } catch (error) {
      dispatch({
        type: GET_ALL_ORDERS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getOrderByID = (id) => async (dispatch, getState) => {
  try {
    const {
      adminLogin: { admin },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${admin.token}`,
      },
    };

    dispatch({ type: GET_ORDER_BY_ID_REQUEST });
    const { data } = await axios.get(`/api/order/${id}`, config);

    dispatch({
      type: GET_ORDER_BY_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ORDER_BY_ID_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getAllOrdersDashboard = () => async (dispatch, getState) => {
  try {
    const {
      adminLogin: { admin },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${admin.token}`,
      },
    };

    // Dispatch an action to indicate that the request has started
    dispatch({ type: GET_ALL_ORDERS_DASHBOARD_SUCCESS });

    // Make the API request
    const response = await axios.get('/api/order/dashboard', {
      headers: config.headers,
    });

    // Dispatch an action with the successful response data
    dispatch({
      type: GET_ALL_ORDERS_DASHBOARD_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    console.log(error)
    // Dispatch an action with the error message
  }
};


export const getAllPaidOrders = (page, name, from, to) => async (dispatch, getState) => {
  try {
    const {
      adminLogin: { admin },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${admin.token}`,
      },
    };

    dispatch({ type: GET_ALL_PAID_ORDERS_REQUEST });
    const response = await axios.get("/api/order/paid", {
      params: { page, name, from, to },
      headers: config.headers,
    });
    const { pagination, orders } = response.data;
    dispatch({
      type: GET_ALL_PAID_ORDERS_SUCCESS,
      payload: { pagination, orders },
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_PAID_ORDERS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};


export const getAllUnpaidOrders = (page, name, from, to) => async (dispatch, getState) => {
  try {
    const {
      adminLogin: { admin },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${admin.token}`,
      },
    };

    dispatch({ type: GET_ALL_UNPAID_ORDERS_REQUEST });
    const response = await axios.get("/api/order/unpaid", {
      params: { page, name, from, to },
      headers: config.headers,
    });
    const { pagination, orders } = response.data;
    dispatch({
      type: GET_ALL_UNPAID_ORDERS_SUCCESS,
      payload: { pagination, orders },
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_UNPAID_ORDERS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
