import React, { useState, useEffect } from "react";
import Sidebar from "../components/Sidebar";
import { getAllOrders } from "../actions/orderActions";
import { useDispatch, useSelector } from "react-redux";
import { getAllPromocodes } from "../actions/promocodeActions";
const PromoCodeScreen = () => {
  const dispatch = useDispatch();

  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");

  const getPromocode = useSelector((state) => state.getPromocode);

  const { promocode } = getPromocode;

  const allOrders = useSelector((state) => state.allOrders);
  const { orders } = allOrders;

  useEffect(() => {
    dispatch(getAllPromocodes());
    dispatch(getAllOrders());
  }, []);

  const searchQuery = () => {
    dispatch(getAllOrders("", from, to));
  };

  return (
    <div className="container-fluid ps-0 ">
      <div className="row ">
        <div className="col-lg-2">
          <Sidebar active={12} />
        </div>
        <div
          className="col-lg-10 p-0"
          style={{
            overflowY: "scroll",
            height: "100vh",
          }}
        >
          <div className="mt-3 p-3">
            <div className="d-flex align-items-center justify-content-between pe-3">
              <h4 className="mb-3 fw-bold ">Promocodes</h4>
              <div className="d-flex  align-items-center">
                <div class="form-floating mb-3 me-3">
                  <input
                    type="date"
                    class="form-control"
                    id="floatingInput"
                    value={from}
                    onChange={(e) => setFrom(e.target.value)}
                    placeholder="name@example.com"
                  />
                  <label for="floatingInput text-grey ">From</label>
                </div>
                <div class="form-floating mb-3 me-3">
                  <input
                    type="date"
                    class="form-control"
                    id="floatingInput"
                    value={to}
                    onChange={(e) => setTo(e.target.value)}
                    placeholder="name@example.com"
                  />
                  <label for="floatingInput text-grey">To</label>
                </div>
                <button
                  onClick={searchQuery}
                  className="btn  btn-primary text-light"
                >
                  <i class="fa-solid fa-magnifying-glass"></i>
                </button>
              </div>
            </div>
            {orders && (
              <div className="d-flex">
                <div>
                  <h3 className=" d-line fw-bold pe-3 text-danger">
                    {orders && orders.filter((order) => order.promoCode).length}
                  </h3>{" "}
                </div>

                <h3 className="ps-3 d-line fw-bold pe-3 text-muted">|</h3>
                <h3 className="ps-3 d-line fw-bold text-success">
                  {orders &&
                    orders.filter(
                      (order) => order.paymentStatus && order.promoCode
                    ).length}
                </h3>
              </div>
            )}

            <table className=" w-100">
              <thead>
                <tr className="">
                  <th className="p-4">Sr.No</th>
                  <th className="p-4">Name</th>
                  <th className="p-4">Code</th>
                  <th className="p-4">Desc</th>
                  <th className="p-4">Total Orders</th>
                  <th className="p-4">Total Paid Orders</th>
                </tr>
              </thead>
              <tbody className="bg-dark-1">
                {promocode &&
                  promocode.map((item, i) => (
                    <tr>
                      <td className="ps-4 pt-3 pb-3 ">{i + 1}</td>
                      <td className="ps-4 pt-3 pb-3 ">{item.name}</td>
                      <td className="ps-4  pt-3 pb-3 ">{item.code}</td>
                      <td className="ps-4 pt-3 pb-3 ">{item.desc}</td>
                      <td className="ps-4 pt-3 pb-3 ">
                        {orders &&
                          orders.filter(
                            (order) =>
                              order.promoCode &&
                              order.promoCode.code == item.code
                          ).length}
                      </td>
                      <td className="ps-4 pt-3 pb-3 ">
                        {orders &&
                          orders.filter(
                            (order) =>
                              order.paymentStatus &&
                              order.promoCode &&
                              order.promoCode.code == item.code
                          ).length}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PromoCodeScreen;
