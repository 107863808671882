import React, { useEffect } from "react";
import Sidebar from "../components/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import "../css/dashboard.css";
import { getAllTestrides } from "../actions/formActions";
import Loader from "../components/Loader";
import { getAllorders, getAllOrdersDashboard } from "../actions/orderActions";

const DashboardScreen = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllOrdersDashboard());
    dispatch(getAllTestrides());
  }, []);

  const allOrders = useSelector((state) => state.dashboard);
  const { order } = allOrders;

  const getTestrides = useSelector((state) => state.getTestrides);
  const { testRides } = getTestrides;

  const currencyFormat = (num) => {
    return (
      "Rs" + " " + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
    );
  };

  return (
    <div className="container-fluid   ps-0 ">
      <div className="row  ">
        <div className="col-lg-2 ">
          <Sidebar active={1} />
        </div>

        <div className="col-lg-10">
          {order ? (
            <div
              className="row"
              style={{
                overflowY: "scroll",
                height: "100vh",
              }}
            >
              <div className="col-3 p-3  ">
                <div
                  className="dashborad-card  p-4"
                  style={{
                    borderTopRightRadius: "10px",
                    borderBottomRightRadius: "10px",
                    borderLeft: "4px solid blue",
                  }}
                >
                  <div className="d-flex justify-content-between">
                    <div>
                      <h6 className="text-muted">Total Revenue</h6>
                      <h4 className="fw-bold  ">
                        {currencyFormat(
                          order
                            .filter((item) => item.paymentStatus === true)
                            .reduce((acc, item) => acc + item.amount, 0)
                        )}
                      </h4>
                    </div>
                    <div>
                      <i
                        style={{
                          fontSize: "2em",
                        }}
                        class="fa-solid text-muted  fa-wallet"
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-3 p-3  ">
                <div
                  className="dashborad-card  p-4"
                  style={{
                    borderTopRightRadius: "10px",
                    borderBottomRightRadius: "10px",
                    borderLeft: "4px solid #10b068",
                  }}
                >
                  <div className="d-flex justify-content-between">
                    <div>
                      <h6 className="text-muted">Sales</h6>
                      <h5 className="fw-bold  ">
                        {
                          order.filter((item) => item.paymentStatus === true)
                            .length
                        }
                      </h5>
                    </div>
                    <div>
                      <i
                        style={{
                          fontSize: "2em",
                        }}
                        class="fa-solid fa-box-archive text-muted"
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-3 p-3  ">
                <div
                  className="dashborad-card  p-4"
                  style={{
                    borderTopRightRadius: "10px",
                    borderBottomRightRadius: "10px",
                    borderLeft: "4px solid #2a6315",
                  }}
                >
                  <div className="d-flex justify-content-between">
                    <div>
                      <h6 className="text-muted">Lead</h6>
                      <h5 className="fw-bold  ">{order.length}</h5>
                    </div>
                    <div>
                      <i
                        style={{
                          fontSize: "2em",
                        }}
                        class=" text-muted fa-solid fa-chart-line"
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-3 p-3  ">
                <div
                  className="dashborad-card  p-4"
                  style={{
                    borderTopRightRadius: "10px",
                    borderBottomRightRadius: "10px",
                    borderLeft: "4px solid #bae8ff",
                  }}
                >
                  <div className="d-flex justify-content-between">
                    <div>
                      <h6 className="text-muted">Test Rides</h6>
                      <h5 className="fw-bold  ">
                        {testRides && testRides.length}
                      </h5>
                    </div>
                    <div>
                      <i
                        style={{
                          fontSize: "2em",
                        }}
                        class=" text-muted fa-solid fa-chart-line"
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-8 p-4 border-round  ">
                <p className="fw-bold">Monthly Sales Graph</p>
                <iframe
                  title="sales graph"
                  style={{
                    height: "700px",

                    borderRadius: "15px",
                    background: "#fff",
                    boxShadow: "0px 2px 10px 0 rgba(70, 76, 79, .2)",
                  }}
                  className="w-100"
                  src="https://charts.mongodb.com/charts-project-0-dblfi/embed/charts?id=639d6fdb-c2dd-420d-87ee-35046d5996ac&maxDataAge=3600&theme=light&autoRefresh=true"
                ></iframe>
              </div>
              <div className="col-4 p-4 border-round ">
                <p className="fw-bold">Products</p>

                <iframe
                  className="w-100"
                  title="sku graph"
                  style={{
                    height: "700px",
                    borderRadius: "15px",
                    background: "#fff",
                    boxShadow: "0px 2px 10px 0 rgba(70, 76, 79, .2)",
                  }}
                  src="https://charts.mongodb.com/charts-project-0-dblfi/embed/charts?id=639d98d6-d15f-4d85-878a-6b849c1377e8&maxDataAge=3600&theme=light&autoRefresh=true"
                  // src='https://charts.mongodb.com/charts-project-0-dblfi/embed/charts?id=639d7736-c582-489a-88ef-a66e664265e4&maxDataAge=3600&theme=light&autoRefresh=true'
                ></iframe>
              </div>
              <div className="col-6 p-4 border-round  ">
                <p className="fw-bold">Sales as per State</p>
                <iframe
                  title="sales graph"
                  style={{
                    height: "700px",

                    borderRadius: "15px",
                    background: "#fff",
                    boxShadow: "0px 2px 10px 0 rgba(70, 76, 79, .2)",
                  }}
                  className="w-100 pt-4"
                  src="https://charts.mongodb.com/charts-project-0-dblfi/embed/charts?id=63a01002-b774-4165-829c-b36d3c70414c&maxDataAge=3600&theme=light&autoRefresh=true"
                ></iframe>
              </div>
              <div className="col-6 p-4 border-round  ">
                <p className="fw-bold">Notify Me</p>

                <iframe
                  title="sales graph"
                  style={{
                    height: "700px",

                    borderRadius: "15px",
                    background: "#fff",
                    boxShadow: "0px 2px 10px 0 rgba(70, 76, 79, .2)",
                  }}
                  className="w-100 pt-4"
                  src="https://charts.mongodb.com/charts-project-0-dblfi/embed/charts?id=64749b15-2bd3-412f-89c4-068df9b8abb0&maxDataAge=3600&theme=light&autoRefresh=true"
                ></iframe>
              </div>
            </div>
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </div>
  );
};

export default DashboardScreen;
